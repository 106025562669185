<template>
  <section>
    <div class="richtext">
      <p>
        Ark Electric &amp; Mechanical Co., Ltd (ARK LTD) is a professional
        manufacturer, which specializing in R&amp;D, producing various precise
        metering pump, flow sensor, industrial online monitoring and control
        instruments and dosing control system. ARK LTD is committing itself to
        provide excellent products and service in such fields as waste water
        treatment, pure water process and water purification project,
        petrochemical industry, food and beverage, electrical power, metallurgy,
        pharmacy, paper making, printing and dyeing, bioengineering and
        environment detection.
      </p>
      <p><br /></p>
      <p>
        Found in 2001, the first electromagnetic diaphragm metering pump, named
        NEWDOSE was produced in China by adopting imported technology. For more
        than ten years continual efforts and innovation, the annual sales
        quantity has reached 30,000 sets per year, and it has become the largest
        electromagnetic diaphragm metering pump production base in China.
        Domestic and overseas advanced technology and brand-new design idea are
        adopted for ARK series water quality monitoring instrument. Furthermore,
        focusing on high quality and customer service, first-class management
        idea and perfect after-sale service system are adopted to provide
        customers with the most suitable and economical automation scheme.
      </p>
      <p><br /></p>
      <p>
        ARK LTD has been always attaching importance to talents and technical
        innovation. For now, we boast senior industrial engineers, professional
        R&amp;D team and experienced field technicians. In the meantime, we
        continually perform close technical exchange with domestic and overseas
        companies in this industry based on enhancement of laboratory study,
        ensuring the product innovation and continual development in the
        industry.
      </p>
      <p><br /></p>
      <p>
        The company provides the following products, technology and service:
      </p>
      <p><br /></p>
      <p>Various precise metering pumps:&nbsp;</p>
      <p><br /></p>
      <ul class="list-paddingleft-2" style="list-style-type: disc">
        <li>
          <p>
            NEWDOSE electromagnetic driven diaphragm metering pump: DFD/DM/DP/DC
            series.
          </p>
          <p><br /></p>
        </li>
        <li>
          <p>NEWDOSE mechanical driven diaphragm metering pump:&nbsp;</p>
          <p>NDJ/NDWS/NDWL/HND/ND1000/ND2000 series.</p>
          <p><br /></p>
        </li>
        <li>
          <p>
            Ecodose series mechanical dosing pump Newdose dosing pump is also
            the metering pump which include solenoid driven type and motor
            driven type. It is manufactured by ARK Electric and Mechanical Co.,
            Ltd, founded in the year of 2001.
          </p>
        </li>
      </ul>
      <p><br /></p>
    </div>
  </section>
</template>
<script>
export default {
  title: "Company Profile",
};
</script>